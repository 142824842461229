
import { Options, Vue } from 'vue-class-component'
import theMemberList from '@/components/Frontend/MemberList/index.vue'

@Options({
  components: {
    theMemberList
  }
})
export default class Members extends Vue {}
